'use strict';

var lastQueries = {
    origin: '',
    destination: ''
};

var responseMemorized = {
    origin: null,
    destination: null
};

var isSearching = false;

function createPhoneMask() {
    var mask = require('../components/cleave');

    var phone1 = document.querySelector('.phone1');
    var phone2 = document.querySelector('.phone2');

    if (!!phone1 && !!phone2) {
        if (phone1) {
            mask.handlePhone('.phone1');
        }
        if (phone2) {
            mask.handlePhone('.phone2');
        }
    }
}

function submitDataCharter(event) {
    event.preventDefault();
    var redirectUrl = $(this).data('redirecturl');
    var form = $(this);
    var url = form.attr('action');
    var isDateValid = true;

    if ($('.dateInput').find('input').val().length > 0) {
        $('.dateInput').each(function () {
            var date = new Date($(this).find('input').val());
            date.setDate(date.getDate() + 1);

            var firstDate = new Date($('.dateInput').first().find('input').val());
            firstDate.setDate(firstDate.getDate() + 1);

            if (date < new Date() || date < firstDate) {
                $(this).find('input').addClass('is-invalid');
                $(this).find('.invalid-feedback').text('Data inválida');
                isDateValid = false;
            }
        });
    }

    if (isDateValid) {
        form.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (data.success) {
                    window.location.href = redirectUrl;
                }
            },
            error: function () {
                form.spinner().stop();
            }
        });
    }
}

/**
* Get the search query from the input value
* @param {string} value The input element value
*/
function getQuery(value) {
    if (typeof value !== 'string') {
        throw new Error('getQuery Method must receive a string!');
    }

    var query = value.trim();

    query = query.replace(/[ãáàâéêèîíìõóóôúùû]/ig, function (match) {
        var vogal = '';

        var vogals = [
            ['ãáàâä', 'a'],
            ['éêèë', 'e'],
            ['îíìï', 'i'],
            ['õóóôö', 'o'],
            ['úùûü', 'u']
        ];

        $.each(vogals, function (index, vogalList) {
            var vogalIndex = vogalList[0].indexOf(match);

            if (vogalIndex !== -1) {
                vogal = vogalList[1];
            }
        });

        return vogal;
    });

    query = query.replace(/[\u0300-\u036f]/g, '');

    return query.toUpperCase();
}

/**
* Fetch an object with a list of cities given a city name as a query
* @param {string} travelPoint Can be origins|destionatios
* @param {string} query city name
* @param {function} callback that will be executed with the response returned
*/
function requestCitiesFrom(_travelPoint, query, callback) {
    isSearching = true;

    $.ajax({
        url: $('#fretamento').data('url'),
        method: 'GET',
        data: {
            city: query
        },
        success: function (response) {
            callback(null, response);
            isSearching = false;
        },
        error: function (error) {
            callback(error);
            isSearching = false;
        }
    });
}

/**
* Filter the cities according to the query
* @param {Object} input The input element
*/
function filterOriginsDestinations(travelPoint) {
    var $input = $('.cities__input__charter.cities__' + travelPoint + '__charter');
    var $list = $input.next('.cities__list');
    var $items = $list.find($('.cities__item__charter'));
    var value = $input.val();
    var query = getQuery(value);

    if (query.length >= 1) {
        var isEmpty = true;
        $items.each(function () {
            if ($(this).text().toUpperCase().search(query) > -1) {
                $list.show();
                $(this).show();
                isEmpty = false;
            } else {
                $(this).hide();
            }
        });
        if (isEmpty) {
            $list.hide();
        }
    }
}

/**
 * getChars return a specified numbers of chars from a string, starting from index 0 (zero)
 * @param {string} query query with the city name
 * @param {number} quantity quantity of characteres to return. default: 3
 */
function getChars(query, quantityParam) {
    var quantity = quantityParam || 1;
    return query.slice(0, quantity);
}

/**
* Create a autocomplete list of cities
* @param {string} travelPoint  Can be origin|destionation
* @param {object} data The object received from request
*/
function fillList(travelPointParam, data) {
    var travelPoint = travelPointParam || 'origin';

    var $list = $('#' + travelPoint + '_cities__charter');

    $list.children().remove();

    $.each(data, function (index, item) {
        $list.append('<li class="cities__item__charter text--isNormal" data-id="' + item.id + 'orange-id="result_search_' + index + '">' + item.name + '</li>');
    });

    $('.cities__item__charter').on('click tap', function () {
        $(this).closest('.cities__list').siblings('.cities__input__charter').val($(this).text());
        $(this).closest('.cities__list').next('.cities__input__charter--isHidden').val($(this).attr('data-id'));
        $(this).closest('.cities__list').hide();
    });

    if (data && data.length > 0) {
        $list.show();
    } else {
        $list.hide();
    }
}

/**
* Load a list with all related cities
* @param {*} travelPoint Can be origin|destination
* @param {*} value the value with the city name
*/
function filterCities(travelPoint, value) {
    var stripedQuery = getChars(value);

    // caso menos de 3 caracteres não fazer requisição e deixar lista vazia
    if (value.length < 1) return fillList(travelPoint, []);

    // Caso a query é a mesma pesquisada não realizar nova requisição
    if (lastQueries[travelPoint] === stripedQuery && responseMemorized[travelPoint] !== null) {
        fillList(travelPoint, responseMemorized[travelPoint]);

        if (value.length > 0) {
            filterOriginsDestinations(travelPoint);
        }

        return;
    }

    // Armazenar última busca para não realizar a mesma requisição
    lastQueries[travelPoint] = stripedQuery;

    if (!isSearching) {
        // Fazer requisição para pegar a lista de locais
        requestCitiesFrom(travelPoint + 's', stripedQuery, function (err, response) {
            if (!err && response.success) {
                // cache the response
                responseMemorized[travelPoint] = response.records.map(x => { return { name: x.Municipio__c, id: x.Id }; });
                // fill the list with the response
                fillList(travelPoint, responseMemorized[travelPoint]);

                filterOriginsDestinations(travelPoint);
            }
        });
    }
}

module.exports = function () {
    $(document).ready(createPhoneMask);
    $('form.charter-form').submit(submitDataCharter);

    $('.cities__input__charter.cities__origin__charter').on('keyup', function () {
        filterCities('origin', this.value);
    });

    $('.cities__input__charter.cities__destination__charter').on('keyup', function () {
        filterCities('destination', this.value);
    });

    var isCitiesListVisible = function () {
        return $('.cities__list').is(':visible');
    };

    var isNotCitiesListAndCitiesInput = function (target) {
        return !$(target).is('.cities__list') && !$(target).is('.cities__input');
    };

    $('body').on('click', function (ev) {
        if (isCitiesListVisible() && isNotCitiesListAndCitiesInput(ev.target)) {
            $('.cities__list').hide();
        } else if ($(ev.target).is('.cities__input__charter.cities__origin__charter')) {
            filterOriginsDestinations('origin');
        } else if ($(ev.target).is('.cities__input__charter.cities__destination__charter')) {
            filterOriginsDestinations('destination');
        }
    });
};
